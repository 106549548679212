import PixMaskHelper from './pix_mask_helper';

const PixForm = ($, $formContainer, $submitButton) => {
  const $form = $formContainer.find('.JS-pix-form');

  const $pixTypeSelect = $form.find('#transfer_request_pix_key_type_cd');
  const $pixKeyInput = $form.find('#transfer_request_pix_key');

  const previousPixValue = null;

  const showContainer = ($container) => {
    $container.removeClass('d-none').addClass('d-block');
  };

  const hideContainer = ($container) => {
    $container.removeClass('d-block').addClass('d-none');
  };

  const initPixMaskHelper = () => {
    PixMaskHelper($, $pixTypeSelect, $pixKeyInput);
  };

  const getConsultPixUrl = () => {
    if (!$pixKeyInput.length) return null;

    return $pixKeyInput.data('url-consult-pix');
  };

  const setFieldError = ($input, message) => {
    $input.addClass('is-invalid');

    const $hint = $input.siblings().filter('.invalid-feedback');

    if (!$hint.length) return;

    $hint.html(message);
  };

  const removeFieldError = ($input) => {
    $input.removeClass('is-invalid');

    const $hint = $input.siblings().filter('.invalid-feedback');

    if (!$hint.length) return;

    $hint.empty();
  };

  const onValidateForm = () => {
    const pixType = $pixTypeSelect.val();
    const pixKey = $pixKeyInput.val();
    const pixKeyIsValid = !$pixKeyInput.hasClass('is-invalid');

    if (pixType && pixKey && pixKeyIsValid) {
      $submitButton.removeClass('btn-disabled');
    } else {
      $submitButton.addClass('btn-disabled');
    }
  };

  const fillBeneficiaryInfo = (data) => {
    const {
      pix_key: pixKey,
      name,
      agency,
      account,
      bank_name: bankName,
      document_number: documentNumber,
    } = data;
    const $infoContainer = $formContainer.find('.JS-beneficiary-info');
    const $info = $infoContainer.find('.JS-info');

    $infoContainer.removeClass('d-none');

    $info.find('.name').text(name);
    $info.find('.key').text(pixKey);
    $info.find('.document').text(documentNumber);
    $info.find('.institution').text(bankName);
    $info.find('.agency').text(agency);
    $info.find('.account-number').text(account);
  };

  const PIX_CALLBACKS = {
    onSuccess: (data) => {
      if (data) {
        const $kindCd = $form.find('#transfer_request_kind_cd');

        if ($kindCd.length) $kindCd.val(data.kind_cd);
      }

      fillBeneficiaryInfo(data);
      removeFieldError($pixKeyInput);
      onValidateForm();
    },
    onError: () => {
      const $infoContainer = $formContainer.find('.JS-beneficiary-info');

      $infoContainer.addClass('d-none');

      setFieldError($pixKeyInput, 'Chave não encontrada. Verifique e tente novamente.');
      onValidateForm();
    },
  };

  const consultPix = (pixKey) => {
    const onBeforeSend = xhr => xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));

    $.ajax({
      url: getConsultPixUrl(),
      type: 'post',
      dataType: 'json',
      beforeSend: onBeforeSend,
      data: { key: pixKey },
      success: (data) => {
        if (data.success && data.data) {
          PIX_CALLBACKS.onSuccess(data.data);
        } else {
          PIX_CALLBACKS.onError();
        }
      },
      error: PIX_CALLBACKS.onError,
    });
  };

  const onBlurPixKeyInput = (e) => {
    const $input = $(e.currentTarget);
    const key = $input.val();

    if (!key || previousPixValue === key) return;

    const pixType = $pixTypeSelect.val();
    const cleanString = value => value.replace(/[^a-zA-Z0-9 ]/g, '').replace(' ', '');

    let pixKey = key;

    switch (pixType) {
      case 'cpf':
      case 'cnpj':
        pixKey = cleanString(key);
        break;
      case 'phone':
        pixKey = `+${cleanString(key.replace(' ', ''))}`;
        break;
      default:
        break;
    }

    consultPix(pixKey);
  };

  $form.on('change', '#transfer_request_pix_key_type_cd', (e) => {
    const $select = $(e.currentTarget);
    const value = $select.val();
    const $pixKeyContainer = $form.find('.JS-pix-key-container');
    const $infoContainer = $formContainer.find('.JS-beneficiary-info');

    $infoContainer.addClass('d-none');

    if (value) {
      showContainer($pixKeyContainer);
    } else {
      removeFieldError($pixKeyInput);
      hideContainer($pixKeyContainer);
    }

    $pixKeyInput.val('');
  });

  $formContainer.on('blur', '#transfer_request_pix_key', onBlurPixKeyInput);

  $form.on('blur', 'select', onValidateForm);
  $form.on('keydown', (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      return false;
    }

    return true;
  });

  initPixMaskHelper();
};

export default PixForm;
