function addSelect2() {
  $('.JS-select-autocomplete').select2({
    theme: 'bootstrap4',
  });

  $('.JS-select-ajax.JS-select-beneficiary-id').select2({
    language: 'pt-BR',
    theme: 'bootstrap4',
    ajax: {
      url: '/ip_bank_account/beneficiaries',
      delay: 300,
      data(params) {
        const query = {
          page: params.page || 1,
        };

        const search = [
          'beneficiary_of_BankAccount_type_name',
          'beneficiary_of_BankAccount_type_agency',
          'beneficiary_of_BankAccount_type_account',
          'beneficiary_of_BankAccount_type_bank_code',
          'beneficiary_of_BankAccount_type_document_number',
        ].join('_or_');

        query[`q[${search}_cont]`] = params.term;

        if (this.data('select')) {
          const select = this.data('select');

          Object.entries(select).forEach(([key, value]) => {
            query[`q[${key}]`] = value;
          });
        }

        return query;
      },
      processResults(results) {
        const keyName = this.$element.data('key') || 'uuid';

        return {
          results: results.data.map(item => ({
            id: (keyName === 'id') ? item.id : item.attributes[keyName],
            text: item.attributes['display-name'],
          })),
          pagination: { more: !!results.links.next },
        };
      },
    },
  });

  $('#charge_document_number.JS-find-payer-info').select2({
    language: 'pt-BR',
    theme: 'bootstrap4',
    ajax: {
      url: '/payers/search',
      delay: 300,
      data(params) {
        const query = {
          page: params.page || 1,
        };

        const search = [
          'name',
          'document_number',
          'email',
          'phone',
        ].join('_or_');

        query[`q[${search}_cont]`] = params.term;

        return query;
      },
      processResults(results) {
        return {
          results: results.data.map(item => ({
            id: item.attributes['document-number'],
            text: item.attributes['display-name'],
          })),
          pagination: { more: !!results.links.next },
        };
      },
    },
  });
}

onLoad(($) => {
  addSelect2();
  $('form').on('cocoon:after-insert', () => addSelect2());
  $(document).on('turbolinks:before-cache', () => {
    $('.JS-select-autocomplete').select2('destroy');
    $('.JS-select-ajax.JS-select-beneficiary-id').select2('destroy');
  });

  $('.JS-select-ajax.JS-select-beneficiary-id').on('select2:open', () => {
    const $options = $('.select2-results__options');
    $options.attr('data-qa', 'options-beneficiary');
  });

  $(document).on('turbolinks:load', () => {
    addSelect2();
  });
});
