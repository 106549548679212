/* eslint-disable array-callback-return, consistent-return */
import PixPortability from './pix/pix_portability';

onLoad(($) => {
  const $mainElement = $('#JS-PixKeysIndex');

  if (!$mainElement.length) {
    return;
  }

  const $buttonReclaimPortability = $('.JS-button-reclaim-portability');
  const $buttonCancelPortability = $('.JS-button-cancel-portability');

  const params = new URLSearchParams(window.location.search);
  if (params.get('synchronizing_key') === 'true') {
    Swal.fire({
      title: 'Sua solicitação foi enviada com sucesso!',
      text: 'Aguarde a sincronização. A página será recarregada automaticamente.',
      icon: 'success',
      showConfirmButton: false,
    });

    setTimeout(() => {
      const path = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      window.location.href = path;
    }, 8000);
  }

  function changeSnackbarContent(contents) {
    const observer = new MutationObserver(() => {
      const $modalContent = $('#swal2-title');

      contents.map(({ content, newContent }) => {
        if ($modalContent.text().includes(content)) {
          $modalContent.text(newContent);
          observer.disconnect();
        }
      });
    });

    observer.observe(document, { childList: true, subtree: true });
  }

  function handleSnackbar() {
    const contents = [
      {
        content: 'Existem boletos híbridos registrados com essa chave PIX',
        newContent: `Existem boletos híbridos vinculados a esta chave. 
          A exclusão só será possível após a baixa ou pagamento dos boletos.`,
      },
      {
        content: 'Erro ao criar chave.',
        newContent: `Aconteceu um erro e não foi possível cadastrar a chave Pix. 
          Por favor, aguarde alguns minutos e tente novamente.`,
      },
    ];
    contents.push({
      content: 'Ocorreu um erro na requisição.',
      newContent: contents[1].newContent,
    });
    changeSnackbarContent(contents);
  }

  function handleReclaimPortability() {
    const key = $(this).data('key-to-request');
    const keyType = $(this).data('key-type-to-request');
    const pixPortability = PixPortability($, key, keyType);
    const onSuccess = () => pixPortability.redirect('/bmp/pix/keys');
    const onError = (error) => {
      if (error.message !== 'Não é possível solicitar um novo código MFA pois o último ainda está válido') {
        return true;
      }
      pixPortability.redirect('/bmp/pix/keys');
    };

    pixPortability.requestClaimMfa({ onSuccess, onError });
  }

  function handleCancelPortability() {
    $('.modal-left .close-btn').click();
  }

  $buttonCancelPortability.on('click', handleCancelPortability);
  $buttonReclaimPortability.on('click', handleReclaimPortability);
  handleSnackbar();

  document.addEventListener('ajax:success', (event) => {
    const $buttonRefreshStatus = $(event.target);
    const key = $buttonRefreshStatus.data('key-to-request');
    const keyType = $buttonRefreshStatus.data('key-type-to-request');
    const id = $buttonRefreshStatus.data('key-portability-id');
    const pixPortability = PixPortability($, key, keyType);
    const response = pixPortability.portabilityProcess(event);
    if (!response || !response.consult_key_portability || !response.finished) return;

    const $modal = $(`#fallow-pix-key-portability-modal-${id}`);
    const $tag = $(`#tag-requested-portability-${id}`);
    $buttonRefreshStatus.hide();
    $tag.hide();
    $modal
      .find('.timeline-vertical-item .icon-container')
      .removeClass(['warning', 'neutral'])
      .addClass('success');
    $modal.on('hidden.bs.modal', () => window.location.reload());
    $buttonCancelPortability.removeClass('d-flex').hide();
  });
});
