import WebWorker from '../web_worker';
import workerTimer from './worker_timer';

function Timer({
  date,
  milliseconds: _milliseconds,
  onTimer,
  onClearTimer,
  onBeforeTimer,
}) {
  if (!date) {
    onClearTimer();
    return;
  }

  const currentDate = new Date();
  let milliseconds = _milliseconds;

  const convertDateToMilliSeconds = (_date) => {
    const hours = _date.getHours() * 3600000;
    const minutes = _date.getMinutes() * 60000;
    const seconds = _date.getSeconds() * 1000;
    return hours + minutes + seconds;
  };

  const dateMilliseconds = convertDateToMilliSeconds(date);
  const currentDateMilliseconds = convertDateToMilliSeconds(currentDate);
  const isTimeExpired = (dateMilliseconds + milliseconds) < currentDateMilliseconds;
  const isDateValid = currentDate.toLocaleDateString('pt-br') === date.toLocaleDateString('pt-br');

  if (!isDateValid || isTimeExpired) {
    onClearTimer();
    return;
  }

  milliseconds -= (currentDateMilliseconds - dateMilliseconds);
  onBeforeTimer();

  const worker = new WebWorker(workerTimer);
  worker.postMessage({ milliseconds });
  worker.addEventListener('message', (event) => {
    const { minutes, clearTimer } = event.data;
    onTimer(minutes);
    if (clearTimer) {
      onClearTimer();
      worker.terminate();
    }
  });
}

export default Timer;
