onLoad(($) => {
  const $filters = $('.JS-pix-history-filters');

  if (!$filters.length) return;

  const $requestType = $filters.find('#q_source_cd_eq');
  const $transferResquestImport = $filters.find('#q_pix_transfer_request_import_id_or_transfer_request_import_id_eq');

  $requestType.on('change', (e) => {
    const $select = $(e.currentTarget);

    if (!$select.length) return;

    const value = $select.find(':selected').val();
    const canDisable = value !== 'import_file';

    $transferResquestImport.prop('disabled', canDisable);

    if (canDisable) $transferResquestImport.val('');
  });

  $requestType.trigger('change');
});
