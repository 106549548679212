import PixForm from './pix_form';
import TedForm from './ted_form';

onLoad(($) => {
  const $remainingBalance = $('.JS-remaining-balance');

  if (!$remainingBalance.length) return;

  const $radioInputs = $('input[name="transfer_type"]');
  const $transferForms = $('.transfer-forms');

  const $pixFormContainer = $transferForms.find('.pix-form');
  const $tedFormContainer = $transferForms.find('.ted-form');

  const $pixCardContainer = $remainingBalance.find('.pix-card');
  const $tedCardContainer = $remainingBalance.find('.ted-card');

  const $submitButton = $remainingBalance.find('.JS-continue-transfer');

  const showContainer = ($container) => {
    $container.removeClass('d-none').addClass('d-block');
  };

  const hideContainer = ($container) => {
    $container.removeClass('d-block').addClass('d-none');
  };

  const containerToggle = ($containerToShow, $containerToHide) => {
    showContainer($containerToShow);
    hideContainer($containerToHide);
  };

  const showPixForm = () => {
    if (!$submitButton.hasClass('btn-disabled')) $submitButton.addClass('btn-disabled');

    containerToggle($pixFormContainer, $tedFormContainer);
    containerToggle($pixCardContainer, $tedCardContainer);

    const $tedForm = $tedFormContainer.find('.JS-ted-form');

    if (!$tedForm.length) return;

    $tedForm[0].reset();
  };

  const showTedForm = () => {
    if (!$submitButton.hasClass('btn-disabled')) $submitButton.addClass('btn-disabled');

    containerToggle($tedFormContainer, $pixFormContainer);
    containerToggle($tedCardContainer, $pixCardContainer);

    const $pixForm = $pixFormContainer.find('.JS-pix-form');
    const $pixKeyContainer = $pixFormContainer.find('.JS-pix-key-container');

    if (!$pixForm.length) return;

    hideContainer($pixKeyContainer);
    $pixForm[0].reset();
  };

  const showConfirmationDialog = ($form, callback = () => { }) => {
    const balance = $form.find('#balance_currency_formated').val();

    Swal.fire({
      title: 'Atenção',
      html: `<p class='mb-4 mx-3'>Você está prestes a aprovar a transferência do
               saldo remanescente para a conta indicada no valor de
               <strong>${balance}</strong>.
             </p>
             <div class='callout warning'>
               Antes de concluir, certifique-se que os dados estão corretos. Redobre a atenção e
               <strong>cuidado com golpes</strong>.
             </div>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      allowOutsideClick: false,
      customClass: {
        actions: 'flex-row-reverse',
        popup: 'swal-confirm-modal',
        title: 'title',
        content: 'content',
      },
    }).then(callback);
  };

  const showTransferRequestError = (error) => {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: true,
      confirmButtonText: 'Ok',
      timer: 10000,
      timerProgressBar: false,
      icon: 'warning',
      title: error,
    });
  };

  const initAuthModalObserver = (callback = () => { }) => {
    const $body = $('body');

    const documentObserver = new MutationObserver(() => {
      const selector = '.swal2-modal .pin-dialog-cancel-btn, .swal2-modal .two-factor-dialog-cancel-btn';
      const $modalCancelButton = $body.find(selector);

      if ($modalCancelButton.length > 0) {
        $modalCancelButton.on('click', callback);
      }
    });

    const options = {
      attributes: false, childList: true, characterData: false, subtree: true,
    };

    documentObserver.observe(document, options);
  };

  const toggleBtnLoadIndicator = ($button, text, isLoading = false) => {
    const html = "<span class='spinner-border spinner-border-sm text-light' role='status'></span> Aguarde...";

    if (isLoading) {
      $button.html(html);
      $button.prop('disabled', true);
    } else {
      $button.html(text);
      $button.prop('disabled', false);
    }
  };

  const disableFooterButtons = (isLoading = false) => {
    const $footer = $remainingBalance.find('.JS-footer');
    const $continueButton = $footer.find('.JS-continue-transfer');

    if (isLoading) {
      $footer.find('button').prop('disabled', true);
      toggleBtnLoadIndicator($continueButton, 'Continuar', true);
    } else {
      $footer.find('button').prop('disabled', false);
      toggleBtnLoadIndicator($continueButton, 'Continuar', false);
    }
  };

  const createTransfer = ($formContainer) => {
    const $form = $formContainer.find('form').filter('.approve');
    const $formReject = $formContainer.find('form').filter('.reject');
    const formData = $form.serializeArray();
    const params = {};

    formData.forEach(({ name, value }) => {
      if (!name.includes('transfer_request') || name.includes('handle_approvation')) return;

      params[name.replace('transfer_request[', '').replace(']', '')] = value;
    });

    disableFooterButtons(true);

    $.ajax({
      url: $form.data('create-transfer-url'),
      type: 'post',
      dataType: 'json',
      data: { transfer_request: params },
      success: (data) => {
        disableFooterButtons(false);

        if (data.success) {
          showConfirmationDialog($form, (result) => {
            if (result && !result.isConfirmed) {
              $formReject.submit();
              return;
            }

            initAuthModalObserver(() => $formReject.submit());

            const $handleApprovation = $form.find('#transfer_request_handle_approvation');

            $handleApprovation.val('approve');

            $form.submit();
          });
        } else if (data.error) showTransferRequestError(data.error);
      },
      error: (data) => {
        disableFooterButtons(false);

        if (data.error) showTransferRequestError(data.error);
      },
    });
  };

  const onSubmitTransfer = () => {
    const $currentFormContainer = $remainingBalance.find('.JS-pix-transfer, .JS-ted-transfer').filter(':visible');

    if (!$currentFormContainer.length) return;

    createTransfer($currentFormContainer);
  };

  $radioInputs.on('change', (e) => {
    const $input = $(e.currentTarget);
    const value = $input.val();
    const $infoContainer = $pixFormContainer.find('.JS-beneficiary-info');

    $infoContainer.addClass('d-none');

    if (value === 'pix') {
      showPixForm();
    } else {
      showTedForm();
    }
  });

  $submitButton.on('click', onSubmitTransfer);

  PixForm($, $pixFormContainer, $submitButton);
  TedForm($, $tedFormContainer, $submitButton);
});
