/* eslint-disable class-methods-use-this */

import CurrencyHelper from '../helper/currencyHelper';
import UserAuth from '../user_auth';

export const approveInAppDialogContent = {
  icon: 'warning',
  title: 'Aprovar solicitação',
  confirmButtonText: 'Ok',
  customClass: {
    container: 'modal-approve-in-app',
  },
  showCloseButton: true,
  html: `
    <div>
      <p>Para concluir, verifique a notificação enviada através do aplicativo <strong>Grafeno Autenticador</strong>
       ou acesse a área de notificações no aplicativo e <strong>confirme a aprovação</strong> antes que o prazo expire.
      </p>
      <p class="modal-approve-in-app-content">
        Caso não haja saldo disponível, não será possível processar o pagamento.
      </p>
    </div>
  `,
};

export default class TransferRequestApproveBase {
  constructor($element) {
    this.$element = $element;
    this.options = this.$element.data('approveTransferRequest');
    this.defaultErrorMessage = 'Não foi possível aprovar a(s) transferência(s)';
    this.targetClass = 'approve-transfer-request';
    this.isIndividual = this.$element.data('action').includes('individual');
    this.isBatch = this.$element.data('action').includes('all');
  }

  approveTransferRequestEvent() {
    if (this.isApproveTransferRequest()) {
      this.approveTransferRequest().catch((error) => {
        // eslint-disable-next-line no-console
        console.log('= Evidenciando erro retornado para análise em teste assistido ========');
        console.error(error);
        // eslint-disable-next-line no-console
        console.log('= Fim da evidência ===================================================');
        this.errorDialog();
      });
      return false;
    }

    return true;
  }

  async approveTransferRequest() {
    const requiresApp = this.options.approve_in_app;
    const requiresPin = this.options.pin;
    const requires2fa = this.options.two_factor;

    const quant = this.options.quant || 1;
    const confirmResult = await this.confirmDialog(quant);
    if (confirmResult.isDismissed) return;

    const validationLimit = await this.verifyAccountLimit();
    if (!validationLimit.success) {
      const response = await this.showExceededLimitDialog();
      if (response.isDismissed) return;
    }

    if (requiresApp) {
      const approveInApp = await this.approveInAppDialog();
      if (approveInApp.isDismissed) return;
      this.submitApproval();
      return;
    }

    let extraParams = {};

    const userAuth = new UserAuth(requires2fa, requiresPin);
    const validationResult = await userAuth.validate();

    if (!validationResult.success) {
      return;
    }

    extraParams = validationResult.data;

    this.submitApproval(extraParams);
  }

  isApproveTransferRequest() {
    return this.$element.hasClass(this.targetClass);
  }

  toggleSubmitStatus() {
    this.$element.toggleClass(this.targetClass);
  }

  confirmDialog(quant) {
    const currencyHelper = new CurrencyHelper();
    const textHtml = `
      <div class="swal-confirm-modal">
        <div class="title">Atenção</div>
        <div class="content">
          Você está prestes a aprovar
          <strong> ${quant} solicitaç${quant > 1 ? 'ões' : 'ão'} de transferência </strong>
          no valor total de <strong>${currencyHelper.format(this.options.value)}</strong>.
        </div>
        <div class="content">${this.options.prefix || ''}</div>
        <div class="subcontent">
          Caso não haja saldo disponível, não será possível processar o pagamento.</br>
        </div>
        <div class="callout warning mt-3">
          Antes de concluir, certifique-se que os dados estão corretos.
          Redobre a atenção e <strong>cuidado com golpes</strong>.
        </div>
      </div>
    `;

    return Swal.fire({
      html: textHtml,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        actions: 'flex-row-reverse',
        content: 'pt-0',
      },
    });
  }

  verifyAccountLimit() {
    let params = '';

    if (this.isBatch) {
      const ids = new URLSearchParams(this.$element.attr('href').split('?')[1]).getAll('ids[]');
      ids.forEach((id) => {
        params += `ids[]=${id}&`;
      });
      params += `transfer_value=${this.options.value}`;
    }

    if (this.isIndividual) {
      const id = this.$element.data('id');
      params += `ids[]=${id}`;
    }

    const method = 'get';
    const url = `/transfer_requests/validate_account_limit?${params}`;

    return new Promise((success, error) => {
      $.ajax({
        success,
        error,
        url,
        method,
        dataType: 'json',
        beforeSend: (xhr) => {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
      });
    });
  }

  showExceededLimitDialog() {
    const textHtml = `
      <div class="swal-confirm-modal">
        <div class="title">O limite diário de aprovação foi excedido</div>
        <div class="content mt-3">
          <p>Não é possível realizar algumas aprovações pois o valor total excede o limite diário da conta origem.</p>
          <p>
            Ao confirmar, você irá realizar a aprovação somente 
            das solicitações que possuem limite disponível na conta.
          </p>
          <div class="d-flex justify-content-center">
            <div class="callout warning text-left">
              <div>
                As solicitações que não forem aprovadas devido ao limite diário 
                ainda ficarão disponíveis na listagem de aprovação.
              </div>
              <div class="mt-3">
                Verifique os limites diários e se necessário solicite um aumento nos nossos canais de comunicação. 
              </div>
            </div>
          </div>
        </div>
      </div>
    `;

    return Swal.fire({
      html: textHtml,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      icon: 'warning',
      customClass: {
        actions: 'flex-row-reverse',
        footer: 'px-1 text-justify',
        container: 'modal-pending-transfer-limit',
      },
    });
  }

  errorDialog(text) {
    return Swal.fire({
      text,
      title: this.defaultErrorMessage,
      icon: 'error',
      customClass: {
        actions: 'flex-row-reverse',
      },
    });
  }

  approveInAppDialog() {
    return Swal.fire(approveInAppDialogContent);
  }
}
