import Timer from '../helper/timer';

onLoad(($) => {
  const $buttonReceiveInstructions = $('.JS-button-receive-instructions');
  if (!$buttonReceiveInstructions.length) return;

  const $spanCounter = $('.JS-span-timer');
  const storage = localStorage.getItem('@grafeno-timer');
  const session = $('.JS-data-session').data('session');
  const composeDate = () => {
    if (session) return new Date(session);
    if (storage) return new Date(storage);
    return undefined;
  };
  const date = composeDate();

  function handleClearTimer() {
    localStorage.removeItem('@grafeno-timer');
    $spanCounter.text('');
    $buttonReceiveInstructions.removeClass('btn-disabled');
  }

  Timer({
    date,
    milliseconds: 300000,
    onBeforeTimer: () => $buttonReceiveInstructions.addClass('btn-disabled'),
    onTimer: minutes => $spanCounter.text(`Você poderá reenviar o e-mail em ${minutes}`),
    onClearTimer: () => handleClearTimer(),
  });

  $buttonReceiveInstructions.on('click', () => {
    const dateISO = (new Date()).toISOString();
    localStorage.setItem('@grafeno-timer', dateISO);
  });
});
