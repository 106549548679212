onLoad(($) => {
  $('#contacts')
    .on('cocoon:after-insert', () => {
      const phoneMask = val => (val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009');
      const onKeyPress = f => ({ onKeyPress: (val, e, field, options) => field.mask(f(val), options) });
      $('.phone').mask(phoneMask, onKeyPress(phoneMask));
    });

  $('#administrators')
    .on('cocoon:before-remove', (event, administrator) => {
      if (!administrator.data('marked-removed')) {
        event.preventDefault();
        Swal.fire({
          title: 'Você tem certeza que deseja remover?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.value === true) {
            administrator.data('marked-removed', true);
            administrator.find('.btn.remove').click();
            administrator.find('.form-control').addClass('confirmed');
          }
        });
      }
    });
});
