onLoad(($) => {
  const $filters = $('.JS-filter-date-type');

  if (!$filters.length) return;

  const $dateType = $filters.find('#date_type');
  const $dateStart = $filters.find('#q_start_date');
  const $dateEnd = $filters.find('#q_end_date');

  const hasDate = () => !($dateStart.val().length || $dateEnd.val().length);

  $dateType.on('change', (e) => {
    const $select = $(e.currentTarget);

    if (!$select.length) return;

    const value = $select.find(':selected').val();
    const canDisable = value === '';

    $dateStart.prop('disabled', canDisable);
    $dateEnd.prop('disabled', canDisable);

    if (canDisable) {
      $dateStart.val('');
      $dateEnd.val('');
    }
  });

  $dateStart.prop('disabled', hasDate);
  $dateEnd.prop('disabled', hasDate);

  if (hasDate()) {
    $dateType.val('').change();
  }
});
