import { PAGES } from '../helpers/navigation_helper';
import validateFields from '../helpers/validation_helper';

// #region User Form Validation
const userFormValidate = ($form) => {
  /* eslint-disable-next-line max-len, no-useless-escape */
  const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  // #region Private Functions
  const cleanString = value => value.replace(/[^a-zA-Z0-9 ]/g, '').replace(' ', '');

  const getHintTagElement = $input => $input.siblings('.invalid-feedback');
  // #endregion Private Functions

  // #region Validation Functions
  const validateCpfInput = (value) => {
    const parsedValue = cleanString(value);
    const isValid = parsedValue.length === 11;
    const error = value === ''
      ? 'Preencha este campo'
      : 'CPF não possui o tamanho esperado (11 caracteres)';

    return { error, isValid };
  };

  const validateEmailInput = (value) => {
    const isValid = emailRegex.test(value);
    const error = value === '' ? 'Preencha este campo' : 'E-mail não é um e-mail';

    return { error, isValid };
  };

  const validateOnboardPhone = (value) => {
    const parsedValue = cleanString(value);
    const length = parsedValue.length;
    const hasValidLength = [0, 11].includes(length);

    if (hasValidLength) {
      return { isValid: true, error: null };
    }

    return { isValid: false, error: 'Celular é inválido' };
  };
  // #endregion Validation Functions

  const validateFormFields = () => {
    const $inputs = $form.find(':input');

    const options = {
      $inputs,
      validationsFor: {
        email: validateEmailInput,
        document_number: validateCpfInput,
        onboarding_phone: validateOnboardPhone,
      },
      hintTagSelector: getHintTagElement,
    };

    validateFields(options);
  };

  validateFormFields();
};
// #endregion User Form Validation

// #region User Form Validation Async
const validateUserFormAsync = ({ $form, response }) => {
  if (!response) return;

  const firstErrors = response.errors[0];

  if (!firstErrors) return;

  const { user } = firstErrors;

  if (!user) return;

  // #region Private Functions
  const getNestedKeys = (key) => {
    const splittedKey = key.split('.');

    if (splittedKey.length === 2) {
      return splittedKey[1];
    }

    return splittedKey[0];
  };

  const validationKeyFilter = key => getNestedKeys(key) !== 'onboarding_phone';

  const getEmailMessage = error => `E-mail ${error}`;

  const getCpfMessage = error => `CPF ${error}`;

  const getValidations = (keys) => {
    const validationsFor = {};

    keys.forEach((key) => {
      const error = user[key][0];
      const parsedKey = getNestedKeys(key);

      let customMessage = error;

      if (parsedKey === 'email') customMessage = getEmailMessage(error);
      if (parsedKey === 'document_number') customMessage = getCpfMessage(error);

      validationsFor[parsedKey] = () => ({ isValid: !customMessage, error: customMessage });
    });

    return validationsFor;
  };

  const getHintTagElement = $input => $input.siblings('.invalid-feedback');
  // #endregion Private Functions

  const validate = (validationsFor = {}) => {
    const $inputs = $form.find(':input[name="email"], :input[name="document_number"]');

    validateFields({
      $inputs,
      validationsFor,
      hintTagSelector: getHintTagElement,
    });
  };

  const validateInputs = () => {
    const keys = Object.keys(user);
    const filteredKeys = keys.filter(validationKeyFilter);
    const validationsFor = getValidations(filteredKeys);

    validate(validationsFor);
  };

  validateInputs();
};
// #endregion User Form Validation Async

// #region Init User Form
const initUserFormPage = ({
  $,
  ipBankAccountsTable,
  navigator,
  onBeforeProcceed = () => { },
}) => {
  const formContainerSelector = '.JS-pre-invitation form.JS-user-info';
  const $form = $(formContainerSelector);

  if (!$form.length) return undefined;

  const $ipBankAccountsTable = ipBankAccountsTable;

  // #region Private Functions
  const isFirstStepHidden = () => {
    const firstStepIsHidden = $('.JS-pre-invitation').is(':hidden');

    return firstStepIsHidden;
  };

  const hasAnyAccountSelected = () => {
    const selection = $ipBankAccountsTable.getSelection();
    const hasAnySelected = selection.length > 0;

    if (hasAnySelected) {
      return true;
    }

    Swal.fire({
      text: 'É necessário adicionar pelo menos uma conta',
      icon: 'error',
      customClass: { confirmButton: 'close-error-modal' },
    });
    return false;
  };

  // #endregion Private Functions

  // #region Check Form Validity
  const isFormValid = () => {
    if ($form && !$form.length) return false;
    let isValid = true;

    const $inputs = $form.find(':input');

    $inputs.each((_, input) => {
      const $input = $(input);
      const hasValidClass = $input.hasClass('is-valid');

      if (!hasValidClass) isValid = false;
    });

    return isValid;
  };
  // #endregion Check Form Validity

  // #region Form Validations Function
  const onFormValidationComplete = (response) => {
    validateUserFormAsync({ $form, response });

    if (!isFormValid()) {
      $('html').scrollTop(0);
      return;
    }

    navigator.navigate(PAGES.invitation);
  };
  // #endregion Form Validations Function

  // #region Event Handlers Functions
  const onBeforeProcceedToNextStep = () => {
    onBeforeProcceed({
      onSuccess: onFormValidationComplete,
    });
  };

  const onFormValid = () => {
    if (!hasAnyAccountSelected()) return;

    if (onBeforeProcceed) {
      onBeforeProcceedToNextStep();
    } else {
      navigator.navigate(PAGES.invitation);
    }
  };

  const onContinue = () => {
    if (isFirstStepHidden()) return;

    const $userForm = $('.JS-pre-invitation form.JS-user-info');

    $userForm.submit();
  };

  const onClickContinueWithPhone = ($button) => {
    if (isFirstStepHidden()) return;

    $button.off().on('click', onContinue);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    userFormValidate($form);

    if (isFormValid()) {
      onFormValid();
    } else {
      $('html').scrollTop(0);
    }
  };
  // #endregion Event Handlers Functions

  // #region Init Event Handlers
  const initClickEventHandler = () => {
    $('.JS-pre-invitation .JS-continue-without-phone').on(
      'click',
      onContinue,
    );
    $('.JS-pre-invitation form.JS-user-info').on('submit', onSubmit);
  };
  // #endregion Init Event Handlers

  // #region Init Observer
  const initObserver = () => {
    const $body = $('body');

    const documentObserver = new MutationObserver(() => {
      const $modalButton = $body.find('.swal2-confirm').not('.close-error-modal');

      if ($modalButton.length > 0) {
        onClickContinueWithPhone($modalButton);
      }
    });

    const options = {
      attributes: false, childList: true, characterData: false, subtree: true,
    };

    documentObserver.observe(document, options);
  };
  // #endregion Init Observer

  // #region Public Methods
  const onGetValue = () => {
    const serializedValue = $form.serializeArray();
    const formValue = {};

    serializedValue.forEach(({ name, value }) => {
      formValue[name] = value;
    });

    return formValue;
  };

  const METHODS = Object.freeze({
    getValue: onGetValue,
  });
  // #endregion Public Methods

  // #region Init Funtion
  const init = () => {
    initClickEventHandler();
    initObserver();
  };
  // #endregion Init Funtion

  init();

  return METHODS;
};
// #endregion Init User Form

export default initUserFormPage;
