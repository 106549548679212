import CurrencyHelper from './helper/currencyHelper';

onLoad(($) => {
  const searchField = $('.JS-change-ip');

  if (!searchField.length) return;

  const queryUrl = searchField[0].dataset.queryurl;

  const ipAccountsModal = $('#ipAccountsModal');
  const form = $('.JS-change-ip-form');
  const kind = form.find('.JS-change-ip-kind');
  const positiveVisibleBalance = form.find('.JS-change-ip-positive-visible-balance');
  const orderByBalance = form.find('.JS-change-ip-order-by-balance');
  const closingState = form.find('.JS-change-ip-by-closing-state');
  const table = form.find('.JS-change-ip-table');
  const notFoundMessage = form.find('.JS-change-ip-not-found');
  const spinner = form.find('.JS-change-ip-spinner');
  const id = form.find('.JS-change-ip-id');
  const ability = form.data('ability');

  let lastSearch;

  let searchId = 0;

  table.add(notFoundMessage).add(spinner).hide();

  const switchTableOrMessage = (length) => {
    notFoundMessage.toggle(length === 0);
    table.toggle(length !== 0);
  };

  const selectAccount = function (e) {
    e.preventDefault();

    id.val(this.dataset.id);

    e.currentTarget.form.submit();
  };

  const currencyHelper = new CurrencyHelper();
  const checkCircleSvg = `
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10
      0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM14.59
      5.58L8 12.17L5.41 9.59L4 11L8 15L16 7L14.59 5.58Z" fill="currentColor" />
    </svg>
  `;

  const createAccountStatus = (ip) => {
    const tooltipId = `tooltip-ip-account-${ip.id}`;

    if (ip.has_pending_term_acceptance) {
      return `
        <label
          id="${tooltipId}"
          data-toggle="tooltip"
          data-trigger="hover"
          data-placement="top"
          data-title="${ip.is_accepter_of_term
    ? 'Termos e Condições pendentes de aceite.'
    : 'Termos e Condições pendentes de aceite do Usuário Master.'}">
          <i class="fas fa-exclamation-triangle"></i>
        </label>
      `;
    }

    if (!ip.has_first_access) {
      return `
        <div class="tag brand-color">
          NOVA
        </div>
      `;
    }

    return '';
  };

  const createButton = (ip) => {
    const canShowAccepTermsBtn = ip.has_pending_term_acceptance && ip.is_accepter_of_term;

    return $('<button/>', {
      class: `JS-change-ip-select-account btn ${canShowAccepTermsBtn
        ? 'btn-success btn-accept-term'
        : 'btn-secondary'} btn-sm`,
      'data-id': ip.id,
      align: 'center',
      text: canShowAccepTermsBtn ? 'Aceitar' : 'Ir para conta',
    });
  };

  const nameAndDocumentNumber = (name, cnpj) => `${name}<br>${cnpj}`;

  const fillTable = (data) => {
    const $tbody = table.find('tbody');

    $tbody.find('tr').remove();

    const $trs = [];

    $(data).each(function () {
      const $tr = $('<tr/>');
      let action;

      $tr.append(
        $('<td/>', { html: createAccountStatus(this), class: 'table-no-wrap status', data: { container: 'body' } }),
      )
        .append($('<td/>', { text: this.bank_code, class: 'table-no-wrap' }))
        .append($('<td/>', { text: this.agency, class: 'table-no-wrap' }))
        .append($('<td/>', { text: this.account, class: 'table-no-wrap' }))
        .append($('<td/>', {
          html: nameAndDocumentNumber(this.name, this.formatted_bank_document_number),
          class: 'text-container',
        }))
        .append($('<td/>', {
          html: nameAndDocumentNumber(this.associated_to, this.formatted_associated_document_number),
          class: 'text-container',
        }))
        .append($('<td/>', { text: this.kind, class: 'table-no-wrap' }));
      if (ability) {
        $tr.append(
          $('<td/>', { text: currencyHelper.format(this.visible_balance), class: 'table-no-wrap font-weight-bold' }),
        );
      }

      if (this.is_current) {
        action = `<label
                    id="current-account-btn"
                    data-toggle="tooltip"
                    data-trigger="hover"
                    data-placement="top"
                    data-title="Conta atual">
                      <button class="btn btn-disabled" disabled>
                        <i class="fas fa-arrow-right mr-2"></i>
                        Ir para conta
                      </button>
                  </label>`;
      } else {
        action = createButton(this);

        const canShowAccepTermsBtn = this.has_pending_term_acceptance && this.is_accepter_of_term;
        const $icon = canShowAccepTermsBtn
          ? $('<span/>', { html: checkCircleSvg, class: 'mr-2' })
          : $('<i/>', { class: 'fas fa-arrow-right mr-2' });

        $icon.prependTo(action);
      }

      $tr.append($('<td align="center" class="table-no-wrap"/>').append(action));

      $trs.push($tr);
    });

    $tbody.append($trs);

    const $currentAccountBtn = $('#current-account-btn');

    if ($currentAccountBtn.length) $currentAccountBtn.tooltip();

    $(data).each(function () {
      const tooltipId = `tooltip-ip-account-${this.id}`;

      $(`#${tooltipId}`).tooltip();
    });

    $('.JS-change-ip-select-account').on('click', selectAccount);
  };

  const searchData = () => (
    {
      search: searchField.val(),
      positive_visible_balance: positiveVisibleBalance.is(':checked'),
      order_by_balance: orderByBalance.is(':checked'),
      order_by_closing_status: closingState.is(':checked'),
      kind: kind.val(),
    }
  );

  let lastSearchData;

  const search = () => {
    const querySearchId = searchId + 1;
    searchId = querySearchId;

    if (JSON.stringify(lastSearchData) === JSON.stringify(searchData())) return;

    spinner.show();

    setTimeout(() => {
      if (searchId !== querySearchId) return;

      $.ajax({
        type: 'GET',
        dataType: 'json',
        url: queryUrl,
        data: searchData(),
        success(data) {
          spinner.hide();

          if (JSON.stringify(lastSearch) === JSON.stringify(data)) return;

          lastSearchData = searchData();

          fillTable(data);

          switchTableOrMessage(data.length);
        },
        error() { },
      });
    }, 800);
  };

  if (ipAccountsModal.hasClass('show')) {
    window.location.reload();
  }

  ipAccountsModal.on('show.bs.modal', () => {
    search();
  });

  searchField.on('change keyup', () => search());
  kind.add(positiveVisibleBalance).add(orderByBalance).add(closingState).on('change', () => search());
});
