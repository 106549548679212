/* eslint-disable no-param-reassign */

import MultiselectCheckbox from '../multiselect_checkbox';

class TransferRequestIndexMultiselect extends MultiselectCheckbox {
  constructor($element, options = {}) {
    super($element, options);
    this.localStorageKeyName = 'receipts';
    this.storedValues = this.getStoredValues();
    this.downloadFileLimit = this.$parentCheckbox.data('web-file-limit');
    this.zipFileLimit = this.$parentCheckbox.data('zip-file-limit');
  }

  getStoredValues() {
    return JSON.parse(localStorage.getItem(this.localStorageKeyName)) || {};
  }

  resetLocalStorage() {
    this.$itemCheckboxes.each((_, e) => {
      const element = $(e);
      this.storedValues[element.attr('id')] = {
        checked: element.is(':checked'),
        receiptId: element.data('receiptId'),
        state: element.data('state'),
      };
    });

    localStorage.setItem(this.localStorageKeyName, JSON.stringify(this.storedValues));
  }

  getStoredSelectedValues() {
    return Object.values(this.getStoredValues()).filter(v => v.checked);
  }

  storedSelectedValuesCount() {
    return this.getStoredSelectedValues().length;
  }

  hasCheckedItems() {
    /* Assim o usuário não precisa checar algo
    caso tenha mudado a paginação para aparecer o botão de download */
    return this.storedSelectedValuesCount();
  }

  getSelectedItemsText(checkedItemsQuantity = this.checkedItemsQuantity()) {
    // por questões de performance, não levaremos em conta a quantidade de itens totais
    const checkedItemsText = `${checkedItemsQuantity}`;
    return `Total de itens selecionados: <strong>${checkedItemsText}</strong>`;
  }

  downloadNotAllowed() {
    const STATES_ALLOWED_TO_DOWNLOAD = ['finished', 'approved_scheduled'];
    return Object.values(this.getStoredValues())
      .some(storedValue => !STATES_ALLOWED_TO_DOWNLOAD.includes(storedValue.state) && storedValue.checked);
  }

  downloadFilesText() {
    if (!this.downloadFileLimit) return '';

    return `${this.getSelectedItemsText(this.storedSelectedValuesCount())}
            <br/>
            Arquivos: <strong>${Math.ceil(this.checkedItemsQuantity() / this.zipFileLimit)}</strong>
            <br/>
            (máximo de ${this.zipFileLimit} comprovantes por arquivo)
            <br/>
            Limite para download: ${this.downloadFileLimit} comprovantes
            <br/>
            <br/>
            Deseja exportar os itens selecionados para um arquivo para download?
            ${this.downloadNotAllowed() ? `
              <div class="callout-actions-batch">
                <div class="callout-icon-actions-batch">
                  <i class="fa fa-info-circle"></i>
                </div>
                <div class="callout-text-actions-batch">
                  Apenas comprovantes de transferências com status <strong>“Agendamento aprovado”</strong> 
                  e <strong>“Concluída”</strong> serão exportados.
                </div>
              </div>` : ''}
            `;
  }
}

function updateState(multiselect) {
  multiselect.resetLocalStorage();
  let params = '';

  if (this.downloadNotAllowed()) params += 'states_not_allowed_to_download=true';
  else if (multiselect.hasCheckedItems()) {
    multiselect.getStoredSelectedValues().forEach((checkedItem) => {
      params += `ids[]=${checkedItem.receiptId}&`;
    });
  }

  multiselect.toggleActionButtonsVisibility = () => {
    const $buttonDropdownBatchAction = $('.JS-btn-dropdown-batch-action');
    $buttonDropdownBatchAction.toggleClass('d-none', !multiselect.hasCheckedItems());
  };

  multiselect.$actionButtons.each((_, button) => {
    const $btn = $(button);
    const url = $btn.attr('href').split('?')[0];
    const dataset = $btn.data('batchDownloadIpReceipt');

    dataset.downloadLimit = multiselect.downloadFileLimit;
    dataset.text = multiselect.downloadFilesText();
    dataset.selectedCount = multiselect.storedSelectedValuesCount();
    $btn.data('batchDownloadIpReceipt', dataset);

    $btn.attr('href', `${url}?${params}`);
  });
}

const actionButtonsClass = 'btn-transfer-request-history-action';

onLoad(($) => {
  localStorage.removeItem('receipts');

  $('#select_all_transfer_request_history')
    .each((_, element) => new TransferRequestIndexMultiselect($(element), { actionButtonsClass, updateState }));

  $('body').on('change', () => {
    $('#select_all_transfer_request_history')
      .each((_, element) => new TransferRequestIndexMultiselect($(element), { actionButtonsClass, updateState }));
  });
});
