import { enabledButtonForm, disabledButtonForm } from '../helper/validate_form';
import PixPortability from './pix_portability';
import Timer from '../helper/timer';

onLoad(($) => {
  const $buttonPortabilityMfa = $('.JS-btn-portability-mfa');
  if (!$buttonPortabilityMfa.length) return;

  const $buttonPortabilityRequest = $('.JS-btn-portability-request');
  const $inputPortabilityMfa = () => $('.JS-input-portability-mfa');
  const $inputInvalid = () => $('.invalid-feedback');
  const $spanTimer = $('.JS-span-timer');
  const dataClaimMfaTime = $('.JS-btn-portability-mfa').data('claim-mfa-time');
  const key = () => $('#JS-pixKeyInput').val();
  const keyType = () => $('#pixType').val();
  const date = dataClaimMfaTime && new Date(dataClaimMfaTime);
  const MFA_CODE_MAX_LENGTH = 6;

  function handleValidateForm() {
    const $inputRequiredFill = $inputPortabilityMfa().val()
      && $inputPortabilityMfa().val().length >= MFA_CODE_MAX_LENGTH;
    if ($inputRequiredFill) {
      enabledButtonForm($buttonPortabilityRequest);
      return;
    }
    disabledButtonForm($buttonPortabilityRequest);
  }

  $buttonPortabilityMfa.on('click', (event) => {
    const pixPortability = PixPortability($, key(), keyType());
    event.preventDefault();
    const onSuccess = async () => {
      pixPortability
        .snackbarPortability({
          title: 'Código enviado com sucesso',
          icon: 'success',
          timer: 2000,
        })
        .then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) window.location.reload();
        });
    };
    pixPortability.requestClaimMfa({ onSuccess });
  });

  document.addEventListener('ajax:success', (event) => {
    const pixPortability = PixPortability($, key(), keyType());
    const response = pixPortability.portabilityProcess(event);
    if (!response) return;
    if (!response.mfa_invalid) return;
    if ($inputInvalid().length) return;

    $inputPortabilityMfa().addClass('is-invalid');
    $inputPortabilityMfa()
      .parent()
      .append('<div class="invalid-feedback d-block">Código inválido</div>');
  });

  Timer({
    date,
    milliseconds: 300000,
    onBeforeTimer: () => $buttonPortabilityMfa.addClass('btn-disabled'),
    onTimer: minutes => $spanTimer.text(`Você pode enviar novamente em ${minutes}.`),
    onClearTimer: () => {
      $spanTimer.text('');
      $buttonPortabilityMfa.removeClass('btn-disabled');
    },
  });

  $inputPortabilityMfa().on('input change', handleValidateForm);
  handleValidateForm();
});
