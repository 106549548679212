import { CONTEXT } from '../helpers/navigation_helper';

const initUserRoleTable = ({
  $,
  context,
  currentSelection: userAccounts,
  selectOptions: { roles: roleOptions, legacyRoles: legacyRolesOptions },
  onChangeRoleBatch = () => { },
  onChangeRole = () => { },
  onRemoveRole = () => { },
}) => {
  const tableContainerSelector = '.JS-invitation-roles-table';

  const $tableContainer = $(tableContainerSelector);

  if (!$tableContainer.length) return undefined;

  const parentContainerSelector = '.JS-invitation';

  const $parentContainer = $(parentContainerSelector);
  const $batchProfileCard = $parentContainer.find('.JS-batch-profile-card');

  let $userRolesTable = null;
  let hasChangedRole = context === CONTEXT.edit;
  let selectedIpBankAccounts = userAccounts;
  let currentSelectedRoles = [];
  const destroyedRoles = [];

  // #region Private Functions
  const findCurrentSelectedRolesById = (id) => {
    const findRole = selectedRole => selectedRole.ipBankAccountId === id;

    const role = currentSelectedRoles.find(findRole);

    return role || null;
  };

  const setSelectValueOnCurrentPage = () => {
    const $selects = $tableContainer.find('table tbody select');

    $selects.each((_, select) => {
      const $select = $(select);
      const clientId = $select.data('client-id');
      const role = findCurrentSelectedRolesById(clientId);
      const originalId = $select.data('original-id');

      if (role && role.id === originalId) $select.val(role.roleId);
    });
  };

  const setBatchProfileCardVisibility = (canShow) => {
    const isCardVisible = $batchProfileCard.is(':visible');

    if (isCardVisible && canShow) return;

    if (canShow) $batchProfileCard.show().addClass('d-flex');
    else $batchProfileCard.hide().removeClass('d-flex');
  };

  const removeAccountFromListsById = (id) => {
    const filterList = item => item.ipBankAccountId !== id;

    currentSelectedRoles = currentSelectedRoles.filter(filterList);
    selectedIpBankAccounts = selectedIpBankAccounts.filter(filterList);
  };

  const findCurrentSelectedRolesIndexById = (id) => {
    const findItem = item => item.ipBankAccountId === id;

    return currentSelectedRoles.findIndex(findItem);
  };

  const canSetRoleValue = (id) => {
    const role = selectedIpBankAccounts.find(account => account.id === id);

    if (!role) return true;

    if (role.isKindLegacy) return false;

    return true;
  };

  const handleSelectedUserRoles = ({
    ipBankAccountId,
    originalAccountId,
    id,
    roleId,
  }) => {
    const canSetValue = canSetRoleValue(id);

    if (!canSetValue) return;

    const roleIndex = findCurrentSelectedRolesIndexById(ipBankAccountId);

    if (roleIndex >= 0) {
      currentSelectedRoles[roleIndex].roleId = roleId;
      return;
    }

    if (roleId === '') {
      const filterList = item => item.ipBankAccountId !== ipBankAccountId;

      currentSelectedRoles = currentSelectedRoles.filter(filterList);
      return;
    }

    currentSelectedRoles.push({
      ipBankAccountId,
      originalAccountId,
      id,
      roleId,
    });
  };

  const setSelectValueOfCurrentCheckedRows = (roleId) => {
    const $checkboxes = $parentContainer.find("input[type='checkbox']");
    const $filteredCheckboxes = $checkboxes.filter(':checked');

    $filteredCheckboxes.each((_, checkbox) => {
      const $checkbox = $(checkbox);
      const $select = $checkbox.closest('tr').find('select').not(':disabled');

      if ($select.length) $select.val(roleId).trigger('change');
    });
  };

  const markToDestroy = (id) => {
    const findIndex = item => item.id === id;
    const index = currentSelectedRoles.findIndex(findIndex);

    if (index < 0) return;

    const role = currentSelectedRoles[index];

    if (!role) return;

    const destroyKey = '_destroy';

    role[destroyKey] = true;

    destroyedRoles.push(role);
  };
  // #endregion Private Functions

  // #region Render Table Component Functions
  const getRolesOptionsForSelect = (row) => {
    const kind = row.kind_cd;
    const isKindLegacy = row.isKindLegacy;

    const mapRoles = ({ label, value }) => {
      const selected = value === kind ? 'selected=""' : '';

      return `<option value="${value}" ${selected}>${label}</option>`;
    };

    const options = isKindLegacy ? legacyRolesOptions : roleOptions;
    const mappedOptions = options.map(mapRoles);

    return mappedOptions.join('');
  };

  const buildSelectComponent = (row) => {
    const {
      ipBankAccountId,
      ip_bank_account_id: originalAccountId,
      isKindLegacy,
      id,
    } = row;
    const name = `role-account-${ipBankAccountId}`;
    const selectId = `role-${ipBankAccountId}`;

    return `
      <select
        class="form-control"
        data-client-id="${ipBankAccountId}"
        data-account-id="${originalAccountId}"
        ${id ? `data-original-id="${id}"` : ''}
        ${isKindLegacy ? 'disabled' : ''}
        id="${selectId}"
        name="${name}">
          <option value>Selecione um perfil...</option>
          ${getRolesOptionsForSelect(row)}
      </select>
      <p class="mb-0 invalid-feedback"></p>
    `;
  };

  const renderDisplayName = (row) => {
    if (row.name) {
      const {
        account_number: accountNumber, agency, kind, name,
      } = row;
      return `${name} - Agência: ${agency} | Conta: ${accountNumber} (${kind.translated})`;
    }
    return row.account_number;
  };

  const buildSelectWithTooltip = (row) => {
    const id = row.id;
    const selectHtml = buildSelectComponent(row);

    return `
      <span
        class="deprecated-role-tooltip role-tooltip-${id}"
        data-container=".role-tooltip-${id}"
        data-html="true"
        data-placement="top"
        data-toggle="popover"
        data-trigger="hover"
        tabindex="0"
        data-content="Este perfil está ativo, mas restrito às regras já criadas.
          Não é mais possível criar ou editar novas regras utilizando este perfil.
          Para alterar o perfil desta conta, exclua a regra existente e adicione novamente com um perfil atualizado.">
        ${selectHtml}
      </span>
      `;
  };

  const setDeprecatedRolePopover = () => {
    const $tooltips = $tableContainer.find('.deprecated-role-tooltip');

    if (!$tooltips.length) return;

    $tooltips.popover({ trigger: 'hover' });
  };

  const buildDeleteButton = (row) => {
    const { ipBankAccountId } = row;
    const butonSelectorClass = 'JS-user-roles-remove-association';

    return `
      <button
        class="btn btn-danger ${butonSelectorClass}"
        type="button"
        data-client-id="${ipBankAccountId}">
        <i class="fas fa-trash"></i>
      </button>
    `;
  };
  // #endregion Render Table Component Functions

  // #region Init Table
  const initTable = () => {
    currentSelectedRoles = [];

    const chooseSelect = row => (row.isKindLegacy
      ? buildSelectWithTooltip(row)
      : buildSelectComponent(row));

    const idKey = 'ipBankAccountId';

    const tableOptions = {
      columns: ['Conta', 'CNPJ', 'Perfil', 'Acões'],
      startPage: 1,
      idKey,
      modelKeys: [
        { key: idKey },
        { key: 'account_number', render: renderDisplayName },
        { key: 'document_number' },
        { key: 'role', render: chooseSelect },
        { key: 'actions', render: buildDeleteButton },
      ],
      rows: selectedIpBankAccounts,
      selectorPrefix: '.JS-invitation',
      tablePageSize: 10,
    };

    $userRolesTable = $tableContainer.paginatedTable(tableOptions);

    $userRolesTable.setPageChangeCallback(setSelectValueOnCurrentPage);

    setDeprecatedRolePopover();
  };
  // #endregion Init Table

  // #region Event Handler Functions
  const onHandleRemoveRole = (e) => {
    const $button = $(e.currentTarget);

    if (!$button.length) return;

    const id = $button.data('client-id');
    const $select = $button.parent().siblings().find('select');
    const originalId = $select.data('original-id');

    if (originalId) markToDestroy(originalId);

    removeAccountFromListsById(id);

    $userRolesTable.updateRows(selectedIpBankAccounts);

    setSelectValueOnCurrentPage();

    if (onRemoveRole) onRemoveRole();
  };

  const onCheckBoxChange = () => {
    const currentSelection = $userRolesTable.getSelection();
    const canShowRoleBatchCard = currentSelection.length > 1;

    setBatchProfileCardVisibility(canShowRoleBatchCard);
  };

  const onChangeRoleSelect = (e) => {
    const $select = $(e.currentTarget);

    if (!$select.length) return;

    const roleId = $select.val();
    const accountId = $select.data('account-id');
    const clientId = $select.data('client-id');
    const originalId = $select.data('original-id');

    selectedIpBankAccounts.forEach((ip, index) => {
      if (!originalId || !ip.id) return;

      if (ip.id === originalId) {
        selectedIpBankAccounts[index].kind_cd = roleId;
      }
    });

    handleSelectedUserRoles({
      ipBankAccountId: clientId,
      originalAccountId: accountId,
      id: originalId,
      roleId,
    });

    hasChangedRole = true;

    if (onChangeRole) onChangeRole();
  };

  const showRoleBatchSuccessToast = () => {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: true,
      confirmButtonText: 'Ok',
      timer: 10000,
      timerProgressBar: false,
      icon: 'success',
      title: 'O perfil foi aplicado em lote para as contas selecionadas.',
    });
  };

  const showRoleBatchConfirmationDialog = (callback = () => { }) => {
    Swal.fire({
      title: 'Aplicar novo perfil em lote',
      html: `<p class="mb-4">Você já definiu perfis para algumas contas selecionadas.</p>
             <p>Deseja <strong>substituir</strong> os perfis atuais dessas contas
             <strong>pelo novo perfil</strong> selecionado?</p>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        actions: 'flex-row-reverse',
        popup: 'swal-confirm-modal',
        title: 'title',
        content: 'content',
      },
    }).then(callback);
  };

  const handleRoleBatchCallback = () => {
    const $role = $parentContainer.find('#JS-batch-role-select');

    if (!$role.length) return;

    const roleId = $role.val();

    setSelectValueOfCurrentCheckedRows(roleId);

    const currentSelection = $userRolesTable.getSelection();

    currentSelection.forEach(selected => handleSelectedUserRoles({
      ipBankAccountId: selected.ipBankAccountId,
      originalAccountId: selected.originalAccountId,
      id: selected.id,
      roleId,
    }));

    const $card = $parentContainer.find('.JS-batch-profile-card');

    $card.hide().removeClass('d-flex');
    $role.val('').change();
    $userRolesTable.setSelection([]);

    showRoleBatchSuccessToast();

    if (onChangeRoleBatch) onChangeRoleBatch();
  };

  const onHandleRoleBatchAction = () => {
    if (hasChangedRole) {
      showRoleBatchConfirmationDialog((result) => {
        if (result && !result.isConfirmed) return;

        handleRoleBatchCallback();
      });

      return;
    }

    handleRoleBatchCallback();
  };

  const handleOnRoleBatchSelectChange = (e) => {
    const $applyButton = $parentContainer.find('#JS-apply-batch-role');
    const $select = $(e.currentTarget);
    const value = $select.val();

    $applyButton.attr('disabled', !value);
  };

  const onCancelBatchAction = () => {
    const $tableFooter = $parentContainer.find('.JS-table-footer');

    if (!$tableFooter.length) return;

    $tableFooter.hide();
  };

  const setRolesForEditTable = (ip, previousRoles) => {
    const role = previousRoles.find(
      previousSelectedItem => previousSelectedItem.ipBankAccountId === ip.ipBankAccountId,
    );

    let currentRoleId = null;

    if (role) {
      const roleId = role.roleId;

      if (!role.id) {
        currentRoleId = roleId;
      }
    }

    handleSelectedUserRoles({
      ipBankAccountId: ip.ipBankAccountId,
      originalAccountId: ip.ip_bank_account_id,
      roleId: ip.kind_cd || currentRoleId,
    });
  };
  // #endregion Event Handler Functions

  // #region Event Handlers
  const initClickEventHandler = () => {
    $tableContainer.on(
      'click',
      '.JS-user-roles-remove-association',
      onHandleRemoveRole,
    );
    $parentContainer.on(
      'click',
      '#JS-apply-batch-role',
      onHandleRoleBatchAction,
    );
    $parentContainer.on(
      'click',
      '.JS-client-users-roles-cancel-batch-action',
      onCancelBatchAction,
    );
  };

  const initChangeEventHandler = () => {
    $tableContainer.on(
      'change',
      "input[type='checkbox']",
      onCheckBoxChange,
    );
    $tableContainer.on(
      'click',
      '.select-all-rows-toggle .toggle, .select-all-rows-toggle .undo-selection',
      onCheckBoxChange,
    );
    $tableContainer.on(
      'change',
      'select',
      onChangeRoleSelect,
    );
    $parentContainer.on('change',
      '#JS-batch-role-select',
      handleOnRoleBatchSelectChange);
  };
  // #endregion Event Handlers

  // #region Public Methods
  const onAddNewAccounts = (newAccounts = []) => {
    selectedIpBankAccounts = newAccounts.concat(selectedIpBankAccounts);

    const previousRoles = currentSelectedRoles;

    $userRolesTable.updateRows(selectedIpBankAccounts);

    if (context === CONTEXT.edit) {
      selectedIpBankAccounts.forEach(ip => setRolesForEditTable(ip, previousRoles));
    }
  };

  const onSetSelectedRoles = (newSelectedRoles = []) => {
    currentSelectedRoles = newSelectedRoles;
  };

  const onGetRoles = () => {
    const currentRoles = currentSelectedRoles.concat(destroyedRoles);

    return currentRoles;
  };

  const METHODS = Object.freeze({
    addNewAccounts: onAddNewAccounts,
    cancelBatchAction: onCancelBatchAction,
    getRoles: onGetRoles,
    setRoles: onSetSelectedRoles,
    selectedAccountsCount: () => selectedIpBankAccounts.length,
    fillSelectInputs: setSelectValueOnCurrentPage,
  });

  const setCustomTableMethods = () => {
    Object.keys(METHODS).forEach((key) => {
      $userRolesTable[key] = METHODS[key];
    });
  };
  // #endregion Public Methods

  // #region Init
  const init = () => {
    initTable();
    initClickEventHandler();
    initChangeEventHandler();
    setCustomTableMethods();
  };
  // #endregion Init

  init();

  return $userRolesTable;
};

export default initUserRoleTable;
