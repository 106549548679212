class IpReceiptsBatchDownload {
  constructor($element) {
    this.$element = $element;
    this.options = this.$element.data('batchDownloadIpReceipt');
    this.defaultErrorMessage = 'Não foi possível iniciar o download';
    this.targetClass = 'JS-batch-download-ip-receipt';
    this.$element.click(() => this.clickEvent());
  }

  clickEvent() {
    if (!this.isSubmitting()) {
      this.requestDownlaod().catch(() => this.errorDialog());
      return false;
    }

    return true;
  }

  async requestDownlaod() {
    if (this.isAboveDownloadLimit()) {
      this.showExceededLimitDialog();
      return;
    }

    const confirmResult = await this.confirmDialog();
    if (confirmResult.isDismissed) return;

    this.submitRequest();
  }

  isSubmitting() {
    return !this.$element.hasClass(this.targetClass);
  }

  toggleSubmitStatus() {
    this.$element.toggleClass(this.targetClass);
  }

  submitRequest() {
    this.toggleSubmitStatus();
    this.$element[0].click();
    this.toggleSubmitStatus();
  }

  confirmDialog() {
    const customClassContainer = ('custom_class' in this.options) ? this.options.custom_class : '';
    return Swal.fire({
      title: this.options.title || '',
      html: this.options.text || '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        actions: 'flex-row-reverse',
        container: customClassContainer,
      },
    });
  }

  isAboveDownloadLimit() {
    return this.options.selectedCount > this.options.downloadLimit;
  }

  showExceededLimitDialog() {
    const textHtml = `
      <div>
        <p><b>Máximo permitido</b>: ${this.options.downloadLimit} arquivos.</p>
        <p><b>Selecionados:</b> ${this.options.selectedCount} arquivos.</p>
      </div>
    `;

    return Swal.fire({
      title: 'Limite para download excedido',
      html: textHtml,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'Ok',
    });
  }

  errorDialog(text) {
    return Swal.fire({
      text,
      title: this.defaultErrorMessage,
      icon: 'error',
      customClass: {
        actions: 'flex-row-reverse',
      },
    });
  }
}

onLoad(($) => {
  $('a.JS-batch-download-ip-receipt').each((_, element) => new IpReceiptsBatchDownload($(element)));
});
