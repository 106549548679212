import TransferRequestHelper from './helper/TransferRequestHelper';

onLoad(($) => {
  if (!$('.ds-automatice-rules-new').length) return;

  const transferRequestHelper = new TransferRequestHelper();

  const $automaticRuleHourSelect = $('#transfer_request_automatic_rule_hour_cd');
  const $beneficiarySelect = $('#transfer_request_automatic_rule_beneficiary_id');
  const $clientPurposeSelect = $('.client-purpose-select');
  const $confirmTransferBtn = $('.JS-confirm-transfer-btn');
  const $finalizeContainer = $('.JS-finalize-transfer');

  function verifyIputsValue() {
    const inputs = [$automaticRuleHourSelect.val(), $clientPurposeSelect.val()];
    transferRequestHelper.verifyInputs(inputs, $confirmTransferBtn);
  }

  $beneficiarySelect.on('change', () => {
    transferRequestHelper.toggleFinalizeContainer($beneficiarySelect.val() !== null, $finalizeContainer);
    verifyIputsValue();
  });

  $clientPurposeSelect.add($automaticRuleHourSelect).on('change', () => {
    verifyIputsValue();
  });

  $confirmTransferBtn.on('click', event => transferRequestHelper.confirmButtonHandler($(event.target)));
});
