const TransferRequestHelper = () => {
  const toggleFinalizeContainer = (selected, $finalizeContainer) => {
    $finalizeContainer[`${selected ? 'remove' : 'add'}Class`]('d-none');
  };

  const verifyInputs = (inputs, confirmButton) => {
    if (inputs.includes('')) {
      confirmButton.addClass('btn-disabled');
      return;
    }
    confirmButton.removeClass('btn-disabled');
  };

  const confirmButtonHandler = ($confirmButton) => {
    $confirmButton.addClass('btn-disabled');
    Swal.onCancel = () => {
      $confirmButton.removeClass('btn-disabled');
      const $inputRedirect = $('input[name="redirect"]');
      $inputRedirect.remove();
    };
  };

  return { toggleFinalizeContainer, verifyInputs, confirmButtonHandler };
};

export default TransferRequestHelper;
