const PixMaskHelper = ($, $pixType, $pixKey) => {
  if (!$pixType.length || !$pixKey.length) return;

  const PIX_OPTIONS = {
    cpf: { mask: '000.000.000-00', placeholder: '000.000.000-00' },
    phone: { mask: '+55 (00) 00000-0000', placeholder: '+55 (__) _____-____' },
    cnpj: { mask: '00.000.000/0000-00', placeholder: '00.000.000/0000-00' },
    email: { mask: null, placeholder: 'exemplo@mail.com' },
    evp: { mask: null, placeholder: 'Digite ou cole o código' },
  };

  const applyPixMask = (type) => {
    if (!type) {
      $pixKey.unmask();
      return;
    }

    const { mask, placeholder } = PIX_OPTIONS[type];

    if (mask) {
      $pixKey.mask(mask);
    } else {
      $pixKey.unmask();
    }

    if (placeholder) {
      $pixKey.attr('placeholder', placeholder);
    } else {
      $pixKey.attr('placeholder', '');
    }
  };

  const onChangePixType = (e) => {
    const $select = $(e.currentTarget);
    const pixType = $select.val();

    applyPixMask(pixType);
  };

  $pixType.off().on('change', onChangePixType);
};

export default PixMaskHelper;
