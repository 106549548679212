/* eslint-disable no-return-assign, consistent-return, no-unused-expressions */

function PixPortability($, key, keyType) {
  if (!key || !keyType) return;
  const urls = {
    claimMfa: '/bmp/pix/keys/claim_mfa',
    newPortability: '/bmp/pix/keys/new_portability',
  };
  const PHONE_PREFIX = '+55';

  const composeKey = () => {
    if (keyType !== 'phone') return key;
    return key.replace(PHONE_PREFIX, '');
  };

  const paramsNewPortability = `bmp_pix_key[key]=${composeKey()}&bmp_pix_key[key_type_cd]=${keyType}`;

  const redirect = (previousUrl = '') => {
    const href = `${urls.newPortability}?${paramsNewPortability}&previous_url=${encodeURIComponent(previousUrl)}`;
    window.location.href = href;
  };

  const onClose = () => {
    Swal.onCancel();
    delete Swal.onCancel;
  };

  const snackbarPortability = props => Swal.fire(
    Object.assign({
      toast: true,
      position: 'top-end',
      timer: 10000,
    }, props),
  );

  const requestClaimMfa = ({ onSuccess, onError }) => {
    const snackbarError = {
      title: ` Aconteceu um erro e não foi possível enviar o código. 
        Por favor, aguarde alguns minutos e tente novamente.`,
      icon: 'warning',
    };

    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: urls.claimMfa,
      data: { bmp_pix_key: { key: composeKey(), key_type_cd: keyType } },
      error: () => snackbarPortability(snackbarError),
      success: (response) => {
        if (response.success) {
          onSuccess();
          return;
        }
        if (!onError) {
          snackbarPortability(snackbarError);
          return;
        }
        onError(response) && snackbarPortability(snackbarError);
      },
    });
  };

  const modalRequestPortability = () => {
    Swal.fire({
      title: 'Chave vinculada a outra instituição',
      html: ` <div>
                <span>
                  Esta chave está associada a uma conta em outra instituição. 
                  Para utilizá-la nesta conta, será necessário solicitar a portabilidade.
                </span>
                <div class="pt-3">
                  <span>
                    Ao solicitar a portabilidade, você terá até <strong>7 dias corridos</strong> 
                    para confirmar a alteração junto à instituição onde a chave está atualmente cadastrada.
                  </span>
                </div>
              </div>`,
      showCancelButton: true,
      confirmButtonText: 'Solicitar portabilidade',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      customClass: {
        container: 'modal-pix-request-portability',
      },
      icon: 'warning',
      preConfirm: async () => {
        requestClaimMfa({ onSuccess: () => redirect('/bmp/pix/keys/new') });
      },
      onClose,
    });
  };

  const modalPortabilityInProgress = () => {
    Swal.fire({
      title: 'Não foi possível registrar esta chave Pix no momento',
      icon: 'warning',
      html: ` <div>
                <span>
                  Esta chave Pix já está em processo de portabilidade e 
                  por isso não pode ser cadastrada como chave na sua conta.
                </span>
              </div>`,
      confirmButtonText: 'Ok, entendi',
      customClass: {
        container: 'modal-pix-request-portability',
      },
      onClose,
    });
  };

  const portabilityProcess = (event) => {
    const isResponseValid = 'detail' in event && (Array.isArray(event.detail)) && event.detail.length;
    if (!isResponseValid) return;

    const response = event.detail[0];
    const isPortabilityProcess = !response.success && response.call_portability_process;
    const isPortabilityInProgress = !response.success && response.portability_in_progress;
    const isPortabilityMfaInvalid = !response.success && response.mfa_invalid;
    const isPortabilityConsult = response.success && response.consult_key_portability;

    if (isPortabilityProcess) {
      modalRequestPortability();
      return;
    }
    if (isPortabilityInProgress) {
      modalPortabilityInProgress();
      return;
    }
    if (isPortabilityMfaInvalid) {
      snackbarPortability({ title: response.message, icon: 'warning' });
      return response;
    }
    if (isPortabilityConsult) {
      return response;
    }
  };

  return {
    requestClaimMfa, portabilityProcess, snackbarPortability, redirect,
  };
}

export default PixPortability;
