const TedForm = ($, $formContainer, $submitButton) => {
  const ACCOUNT_NUMBER_MAX_LENGTH = 13;
  const CPF_VALID_LENGTH = 11;
  const CNPJ_VALID_LENGTH = 14;

  const $form = $formContainer.find('.JS-ted-form');

  const $documentInput = $form.find('#transfer_request_document_number');
  const $nameInput = $form.find('#transfer_request_name');
  const $kindSelect = $form.find('#transfer_request_kind_cd');
  const $bankCodeSelect = $form.find('#transfer_request_bank_code');
  const $agencyInput = $form.find('#transfer_request_agency');
  const $accountInput = $form.find('#transfer_request_account');

  const showContainer = ($container) => {
    $container.removeClass('d-none').addClass('d-block');
  };

  const hideContainer = ($container) => {
    $container.removeClass('d-block').addClass('d-none');
  };

  const cleanString = value => value.replace(/[^a-zA-Z0-9 ]/g, '').replace(' ', '');

  const setFieldError = ($input, message) => {
    $input.addClass('is-invalid');

    const $hint = $input.siblings().filter('.invalid-feedback');

    if (!$hint.length) return;

    $hint.html(message);
  };

  const removeFieldError = ($input) => {
    $input.removeClass('is-invalid');

    const $hint = $input.siblings().filter('.invalid-feedback');

    if (!$hint.length) return;

    $hint.empty();
  };

  const validateDocumentNumber = (document) => {
    if (!document) return false;

    if (document.length === CPF_VALID_LENGTH || document.length === CNPJ_VALID_LENGTH) return true;

    return false;
  };

  const onDocumentInputBlur = (e) => {
    const $input = $(e.currentTarget);
    const document = cleanString($input.val());
    const isValid = document.length === CPF_VALID_LENGTH || document.length === CNPJ_VALID_LENGTH;

    if (isValid) {
      removeFieldError($documentInput);
    } else {
      setFieldError($documentInput, 'CPF/CNPJ inválido. Verifique e tente novamente.');
    }
  };

  const onValidateForm = () => {
    let isValid = true;

    const document = cleanString($documentInput.val());
    isValid = validateDocumentNumber(document);

    const name = $nameInput.val();
    if (name.length <= 0) isValid = false;

    const kind = $kindSelect.val();
    if (!kind) isValid = false;

    const bankCode = $bankCodeSelect.val();
    if (!bankCode) isValid = false;

    const agency = $agencyInput.val();
    if (agency.length <= 0) isValid = false;

    const account = $accountInput.val();
    if (account.length <= 0 || account.length > ACCOUNT_NUMBER_MAX_LENGTH) isValid = false;

    if (isValid) {
      $submitButton.removeClass('btn-disabled');
    } else {
      $submitButton.addClass('btn-disabled');
    }
  };

  $formContainer.on('blur', '#transfer_request_document_number', onDocumentInputBlur);

  $formContainer.on('input', '#transfer_request_account', (e) => {
    const $input = $(e.currentTarget);
    const value = $input.val();
    const $warning = $formContainer.find('.account-number-warning');

    if (value.length > ACCOUNT_NUMBER_MAX_LENGTH) {
      showContainer($warning);
    } else {
      hideContainer($warning);
    }
  });

  $form.on('keydown', 'input, select', onValidateForm);
  $form.on('keydown', (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      return false;
    }

    return true;
  });
};

export default TedForm;
